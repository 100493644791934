<template>
    <a-dropdown
        @visibleChange="handleVisibleChange"
        :trigger="['click']"
        :overlayClassName="`app-dropdown-menu ${overlayClassNames}`"
        placement="bottomRight"
    >
        <button type="button" class="app-switch-button">
            <img :src="require(`../../assets/img/login/flags/${currentLocale}.svg`)" alt="language">
            <span>{{ $t("common.locales." + currentLocale) }}</span>
        </button>
        <template #overlay>
            <a-menu ref="app-menu" class="app-switch-menu">
                <template v-for="(locale, index) in availableLocales">

                    <a-menu-item class="app-switch-menu__item" :key="locale" @click="changeAppLocale($event, locale)">
                        <img :src="require(`../../assets/img/login/flags/${locale}.svg`)" alt="language">
                        <span>{{ $t("common.locales." + locale) }}</span>
                    </a-menu-item>

                    <a-menu-divider
                        v-show="index + 1 !== availableLocales.length"
                        :key="index"
                        class="app-switch-menu__divider"
                    />

                </template>
            </a-menu>
        </template>
    </a-dropdown>
</template>
<script>
import Localstorage from "@/helpers/localstorage";

export default {
    data: () => ({
        isFirstOpen: true,
        overlayClassNames: "initial",
        observer: null,
    }),
    computed: {
        availableLocales() {
            return Object.keys(this.$i18n.messages)
                .filter(locale => locale !== this.currentLocale);
        },
        currentLocale() {
            return this.$i18n.locale;
        },
    },
    methods: {
        handleVisibleChange(visible) {
            if (!visible && this.isFirstOpen) {
                this.isFirstOpen = false;
                this.overlayClassNames = "";
            }
        },
        changeAppLocale($event, locale) {
            $event.domEvent.stopPropagation();
            Localstorage.setItem("locale", locale);

            this.$i18n.locale = locale;
        }
    }
}
</script>

<style lang="scss">
.app-switch-button {
    min-width: 128px;
    height: 40px;
    background: #F4F4F4;
    color: #070707;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    border-radius: 8px;
    padding: 8px 20px 8px 16px;

    span {
        margin-left: 8px;
    }
}

.app-switch-menu {
    padding: 9px 12px;
    border-radius: 12px !important;
    box-shadow: 0 2px 8px 0 #0000000F !important;
    margin-top: 8px;

    &__item {
        padding: 11px 110px 11px 12px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #454142;
        display: flex;
        gap: 10px;

        &:hover {
            background-color: #F4F4F4;
            border-radius: 8px;
        }
    }
}

.app-dropdown-menu {
    width: 250px;
    height: 108px;
}
</style>
